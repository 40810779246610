.omnix__article-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin: 2rem; */
    /* background: var(--color-subtext); */
    border-radius: 10px;
    /* padding: 10px; */
    min-width: 400px;
    margin-top: 5%;

    flex-basis: 200px;
}

.omnix__article-container img {
    width: 100px;
}

.omnix__article-title h3, .omnix__article-title p {
    text-align: center;
}

@media screen and (max-width: 990px) {
    .omnix__article-container {
    margin: 2rem 5rem;
    }
}