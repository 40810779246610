.omnix__services {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.omnix__services-heading {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    text-align: center;
}

.omnix__services-heading div {
    width: 100px;
    height: 3px;
    background: #FF4820;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
    border-radius: 1.5px;
}

.omnix__services-heading  h1{
margin: 0 0;
}

.omnix__services-container {
    margin-top: 2.5rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 5%;
}

@media screen and (max-width: 990px) {
    .omnix__services {
        flex-direction: column;
    }

    .omnix__services-heading {
        margin: 0 0 2rem 0;
    }

    .omnix__services-container {
        flex: 1;
        flex-direction: column;
        margin-top: 0;
    }
}

@media screen and (max-width: 550px) {
    .omnix__services-heading h1{
        font-size: 28px;
        line-height: 38px;
    }

    .omnix__services-container {
        margin-top: 0;
        gap: 2%;
    }
}