
/* OrganizationChartDemo.css */

.organizationchart-demo .card {
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    
}

.organizationchart-demo .p-organizationchart .p-person {
    padding: 0;
    border: 0 none;
}

.organizationchart-demo .p-organizationchart .node-header, .organizationchart-demo .p-organizationchart .node-content {
    padding: .5em .7rem;
}

.organizationchart-demo .p-organizationchart .node-header {
    background-color: var(--color-footer);
    color: #ffffff;
}

.organizationchart-demo .p-organizationchart .node-content {
    text-align: center;
    border: 1px solid #FF4820;
}

.organizationchart-demo .p-organizationchart .node-content img {
    border-radius: 50%;
}

.organizationchart-demo .p-organizationchart .department-cfo {
    background-color: #042c54;
    color: #ffffff;
}

.organizationchart-demo .p-organizationchart .department-coo {
    background-color: #042c54;
    color: #ffffff;
}

.organizationchart-demo .p-organizationchart .department-cto {
    background-color: #042c54;
    color: #ffffff;
}

.omnix__team-heading {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
    margin-right: 5rem;
}

.omnix__team-heading div {
    width: 130px;
    height: 3px;
    background: #FF4820;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
    border-radius: 1.5px;
}


                 