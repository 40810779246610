.omnix__header {
    display: flex;
    flex-direction: row;
    padding-top: 10rem;    
}

.omnix__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
    -webkit-animation: slide-right 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.2s both;
	        animation: slide-right 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.2s both;
}

.omnix__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size:  62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.omnix__header-content p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;

    color: var(--color-text);

    margin-top: 1.5rem;
}

.omnix__header-content__input button {
    margin-top: 24px;
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    background: #FF4820;
    border-radius: 5px;
    color: #FFFFFF;
    cursor: pointer;
    outline: none;
    padding: 0 1rem;
    border: none;
}

.omnix__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.omnix__header-image img {
    width: 100%;
    height: 100%;
    -webkit-animation: slide-left 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.2s both;
	        animation: slide-left 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.2s both;
}

@media screen and (max-width: 1050px) {
    .omnix__header {
        flex-direction: column;
    }

    .omnix__header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .omnix__header h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .omnix__header p {
        font-size: 16px;
        line-height: 24px;
    }

    .omnix__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .omnix__header h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .omnix__header p {
        font-size: 14px;
        line-height: 24px;
    }

    .omnix__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
}



@-webkit-keyframes slide-left {
    0% {
      -webkit-transform: translateX(200px);
              transform: translateX(200px);
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  @keyframes slide-left {
    0% {
      -webkit-transform: translateX(200px);
              transform: translateX(200px);
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes slide-right {
    0% {
      -webkit-transform: translateX(-200px);
              transform: translateX(-200px);
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  @keyframes slide-right {
    0% {
      -webkit-transform: translateX(-200px);
              transform: translateX(-200px);
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }