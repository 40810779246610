.omnix__features-container__feature-lg {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;

    margin: 1rem;
}

.omnix__features-container__feature-lg-title {
    flex: 1;
    max-width: 180px;
    margin-right: 2rem;
}

.omnix__features-container__feature-lg-title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;

    letter-spacing: -0.04em;
    color: var(--color-subtext);
    margin-bottom: 26px;
}

.omnix__features-container__feature-lg-title div {
    width: 38px;
    height: 3px;
    background: #FF4820;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
    border-radius: 1.5px;
}

.omnix__features-container__feature-lg-text {
    flex: 2;
    max-width: 390px;
    display: flex;
}

.omnix__features-container__feature-lg-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-subtext);
    /* margin-top: 26px; */
}

@media screen and (max-width: 550px) {
    .omnix__features-container__feature-lg-title h1 {
        font-size: 14px;
        line-height: 22px;
    }

    .omnix__features-container__feature-lg-title p {
        font-size: 12px;
        line-height: 20px;
    }

    .omnix__features-container__feature-lg {
        margin: 1rem 0;
    }
}