.form-Container{
    width: 100%;
}

.form-group{
    width: 100%;
    margin-bottom: 2rem ;
}

label {
    font-size: 1.8rem;
}

input, textarea {
    width: 100%;
    font-size: 2rem;
    padding: 1.2rem;
    color: var(--color-text);
    background-color: var(--color-blog);
    outline: none;
    border: none;
    border-radius: 8px;
    margin-top: 1rem;
}

textarea {
    min-height: 250px;
    resize: vertical;
}

.omnix__form-content__input button{
    margin-top: 24px;
    flex: 0.6;
    /* width: 50%; */
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 2rem;
    line-height: 27px;
    background: #FF4820;
    border-radius: 5px;
    color: #FFFFFF;
    cursor: pointer;
    outline: none;
    padding: 0 1rem;
    border: none;
    display: inline-block;
}