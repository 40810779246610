.omnix__contact-wrapper{
    display: flex;
    gap: 5rem;
    margin-top: 7rem;
    justify-content: space-around;
    position: relative;
}

/* .omnix__contact-wrapper::after{
    position: absolute;
    content: '';
    width: 2px;
    height: 50%;
    background-color: var(--color-text);
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
} */

.left {
    width: 100%;
    max-width: 500px;
}

.right{
    max-width: 500px;
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .omnix__contact-wrapper{
        flex-direction: column;
    }
    
    .omnix__contact-wrapper::after{
        display: none;
    }
    .left, .right {
        max-width: 100%;
    }

    .right{
        padding: 4rem 2rem 2rem 2rem;
    }
}