.fab {
    margin-top: 5px;
    
}

.fab_container {
    bottom: 2rem;
    right: 5px;
    position: fixed;
    align-items: center;
    display: flex;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ============== */
@media screen and (max-width: 1024px) {
  .fab_container {
    bottom: 2rem;
    right: 5px;
    position: fixed;
    align-items: center;
    display: flex;
  flex-direction: column;
  justify-content: space-between;
}
}