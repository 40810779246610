.omnix__brand {
    /* display: flex; */
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.omnix__brand-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: var(--color-blog);
    border-radius: 5px;
    margin-top: 2rem;
}


.omnix__brand-container div {
    flex: 1;
    max-width: 180px;
    min-width: 150px;
    margin: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
    
}

.omnix__brand-container img {
    flex: 1;
    max-width: 120px;
    min-width: 90px;
    margin: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

.omnix__brand-text {
    justify-content: center;
    align-items: center;
    text-align: center;
    /* background: var(--color-blog); */
    /* margin: 0; */
}

.omnix__brand-heading {
    width: 130px;
    height: 3px;
    background: #FF4820;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
    border-radius: 1.5px;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    flex: 1;
}