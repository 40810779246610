.omnix__navbar {
    width: 100%;
    display: flex;
    padding: 2rem 4rem;
    z-index: 2;
    position: absolute;
    top: 0;
    gap: 0.8rem;
    border-radius: 0 0 1.5rem 1.5rem;
    background: var(--color-footer);
    backdrop-filter: blur(100px);
}

.omnix__navbar-links {
    flex: 1;
    display: flex;
    /* justify-content: flex-start; */
    align-items: center;
    justify-content: space-between;
}

.omnix__navbar-links_logo {
    margin-right: 2rem;
}

.omnix__navbar-links_logo  img{
    width: 250px;
    height: 54px;
}

.omnix__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.omnix__navbar-links_container p,
.omnix__navbar-menu_container p {
color: #fff;
font-family: var( --font-family);
font-weight: 500;
font-size: 18px;
line-height: 25px;
text-transform: capitalize;
text-decoration: none;

border-style: solid;
border-width: 0px 0px 0px 0px;
margin: 0 1rem;
cursor: pointer;
transition: 0.2s ease;
}

.omnix__navbar-links_container p:hover, .omnix__navbar-menu_container p:hover {
    border-width: 0px 0px 1px 0px;
    border-color: #FF4820;
}

.omnix__navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.omnix__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.omnix__navbar-menu_container p {
    margin: 1rem 0;
}

@media screen and (max-width: 1050px) {
    .omnix__navbar-links_container {
        display: none;
    }

    .omnix__navbar-menu {
        display: flex;
        align-items: center;
    }
}

@media screen and (max-width: 700px) {
    .omnix__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .omnix__navbar {
        padding: 2rem;
    }

    .omnix__navbar-menu_container {
        top: 60px;
    }
}