.contactInfo-container {
    padding: 2rem;
    background-color: var(--color-blog);
    display: flex;
    align-items: center;
    gap: 2rem;
    border-radius: 8px;
    margin-bottom: 2rem;
}

.contactInfo-icon{
    color: var(--color-text);
    padding: 1rem;
    align-items: center;
    justify-content: center;
    background-color: var(--color-subtext-bg-light);
    border-radius: 50%;
}